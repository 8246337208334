<template>
  <MasterDetail
    :cols="cols"
    :opts="opts"
    :actionBarButtons="actionBarButtons"
    :resourceUrl="resourceUrl"
    :canDelete="false"
    descriptionProperty="competencia"
    formTitle="Cadastro Encargos Previdenciários"
    has-year-filter
    @mounted="tableLoadFn = $event.doLoad"
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from 'vuex';
export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(['anoBase']),
    client: function () {
      return this.getClient();
    },
    resourceUrl: function () {
      return `/v1/rh/clientes/${this.client.clientId}/encargos/${this.$route.params.grupoId}`;
    },
    resourceGrupoEncargos: function () {
      return this.apiResource(
        `/v1/rh/clientes/${this.client.clientId}/grupoEncargos`
      );
    },
    resourceDuplicarEncargo: function () {
      return this.apiResource(`v1/rh/clientes/${this.client.clientId}/duplicar-encargo/${this.$route.params.grupoId}?anoBase=${this.anoBase}`);
    },
  },
  created: function () {
    this.resourceGrupoEncargos.get().then((response) => {
      this.opts.grupoEncargos = response;
    });
  },
  data: function () {
    return {
      tableLoadFn: null, // TODO solução temporária, pensar como fazer isso melhor
      actionBarButtons: [
        {
          text: "Duplicar Último Encargo",
          icon: "mdi-content-duplicate",
          action: () => {
            this.resourceDuplicarEncargo.save({}, null).then(() => {
              if (this.tableLoadFn) {
                this.tableLoadFn();
              }
            });
          },
        },
      ],
      cols: [
        { key: "id", name: "ID", hideInform: true },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 4,
        },
        { key: "diasUteis", name: "Dias Úteis", type: this.$fieldTypes.NUMBER, colSize: 4, },
        { key: "horasDia", name: "Horas por Dia", type: this.$fieldTypes.TEXT, colSize: 4, },
        {
          key: "contribuicaoPatronal",
          name: "Contr. Patronal",
          type: this.$fieldTypes.TEXT,
           colSize: 4,
        },
        { key: "RAT", name: "RAT", type: this.$fieldTypes.TEXT, colSize: 4, },
        { key: "FAT", name: "FAP", type: this.$fieldTypes.TEXT,  colSize: 4, },
        {
          key: "OutrasEntidades",
          name: "Outras Entidades",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        { key: "FGTS", name: "FGTS", type: this.$fieldTypes.TEXT,  colSize: 6, },
        { key: "INSS", name: "INSS", type: this.$fieldTypes.TEXT,  hideInform: true },
        {
          key: "grupoId",
          name: "Grupo de Encargos",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "grupoEncargos", key: "id", name: "grupo" },
          defaultValue: this.$route.params.grupoId,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        grupoEncargos: [],
      },
    };
  },
};
</script>